import React, { useState } from "react";
import PropTypes from "prop-types";

import { Paragraph } from "components/core/Typography";
import { Caret } from "components/core/Icons";
import { Tooltip } from "components/core/Tooltip";

import "./FeeTooltip.scss";

const FeeTooltip = ({ fees, totalFees }) => {
	const [showFees, setShowFees] = useState(false);
	return (
		<Tooltip
			className="fees-tooltip"
			onShowTooltip={() => setShowFees(true)}
			onHideTooltip={() => setShowFees(false)}
			triggerId="fees-tooltip-trigger"
			visible={showFees}
			content={
				<>
					<Paragraph
						last
						size="small"
						color="gray"
					>
						The following fees shall apply:{" "}
					</Paragraph>
					<ul>
						{fees.map(
							({ name, price }) =>
								price > 0 && (
									<li key={name}>
										<Paragraph
											last
											size="small"
											color="gray"
										>
											{name} - ${price}
										</Paragraph>
									</li>
								)
						)}
					</ul>
				</>
			}
		>
			<Paragraph
				last
				size="small"
				color="gray"
			>
				+ ${totalFees} in fees
			</Paragraph>
			<Caret />
		</Tooltip>
	);
};

FeeTooltip.propTypes = {
	fees: PropTypes.arrayOf(PropTypes.object),
	totalFees: PropTypes.number
};

export default FeeTooltip;
