import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./Tooltip.scss";

export default function Tooltip({
	visible,
	onShowTooltip,
	onHideTooltip,
	content,
	className,
	triggerId,
	children
}) {
	return (
		<div >
			<div
				id={triggerId}
				onMouseLeave={onHideTooltip}
				onMouseEnter={onShowTooltip}
				onClick={(e) => e.stopPropagation()}
				onKeyDown={(e) => e.stopPropagation()}
				role='button'
				tabIndex={0}
			>
				{children}
			</div>
			<div className={classnames(className, "tooltip", { visible })}>
				{content}
			</div>
		</div>
	);
}

Tooltip.defaultProps = {
	visible: false,
	className: "tooltip"
};

Tooltip.propTypes = {
	visible: PropTypes.bool,
	onShowTooltip: PropTypes.func,
	onHideTooltip: PropTypes.func,
	content: PropTypes.node,
	className: PropTypes.string,
	children: PropTypes.node,
	triggerId: PropTypes.string
};
