import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Select as CoreSelect } from "@sparefoot/react-commons";
import { Text } from "components/core/Typography";

import "./Select.scss";

const Select = ({
	label,
	id,
	options,
	onChange,
	defaultValue,
	className,
	labelProps,
	getRef,
	...rest
}) => (
	<label
		htmlFor={id}
		className={classnames("sf-select", className)}
	>
		<Text
			last
			size="small"
			color="gray"
			{...labelProps}
		>
			{label}
		</Text>
		<CoreSelect
			getRef={getRef}
			id={id}
			onChange={onChange}
			defaultValue={defaultValue}
			options={options}
			{...rest}
		/>
	</label>
);

Select.defaultProps = {
	labelProps: {},
	onChange: () => {},
	className: null,
	defaultValue: null,
	getRef: null,
	name: null
};

Select.propTypes = {
	name: PropTypes.string,
	getRef: PropTypes.object,
	label: PropTypes.string.isRequired,
	labelProps: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	defaultValue: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired
};

export default Select;
