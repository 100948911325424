import React from "react";
import PropTypes from "prop-types";
import FacilityCard from "components/search/FacilityCards/FacilityCard";
import SearchToolbar from "components/search/SearchToolbar/SearchToolbar";

const FacilityCards = ({ cards, layout, totalListings }) => (
		<>
			{cards.map((facility, index) => (
				<>
					<FacilityCard
						key={facility.id}
						layout={layout}
						{...facility}
					/>
					{index + 1 < cards.length && (index + 1) % 20 === 0 && (
						<SearchToolbar
							className="search-toolbar divider"
							count={totalListings}
							currentRange={`${index + 2}-${index + 21 > totalListings ? totalListings : index + 21}`} 
							showSortSelect={false}
						/>
					)}
				</>
			))}
		</>
	);

FacilityCards.propTypes = {
	cards: PropTypes.arrayOf(PropTypes.object),
	layout: PropTypes.oneOf(["wide", "narrow"]),
	totalListings: PropTypes.number
};

export default FacilityCards;
