import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SegmentContextProvider } from "@sparefoot/react-commons/segment";
import { Interactions } from "@sparefoot/segment-react";
import { SORT_TYPES } from "config/filters";
import Select from "components/core/Select";
import SearchPageContext from "pages/SearchPage/SearchPageContext";

const SortSelect = ({ onChange, selected }) => {
	const { setIsFiltering } = useContext(SearchPageContext);
	const handleChange = (e) => {
		setIsFiltering(true);
		onChange(e.target.value);
	};

	// value is appended at useSegmentChangeTracking
	const segmentLabel = "sort-filter";

	return (
		<SegmentContextProvider segmentCategory={Interactions.SEARCH}>
			<Select
				className="results-sort"
				id="sort-select"
				label="Sort By:"
				options={Object.values(SORT_TYPES)}
				onChange={handleChange}
				defaultValue={selected}
				segmentLabel={segmentLabel}
			/>
		</SegmentContextProvider>
	);
};
SortSelect.propTypes = {
	selected: PropTypes.oneOf(Object.values(SORT_TYPES).map((v) => v.value)),
	onChange: PropTypes.func.isRequired
};

export default SortSelect;
