import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Text } from "components/core/Typography";
import Crumb from "components/search/Breadcrumbs/Crumb";

import "./BreadcrumbList.scss";

const BreadcrumbsList = ({ breadcrumbs, allowTruncation }) => {
	const lastIndexNumber = breadcrumbs.length - 1;

	return (
		<ul className="breadcrumbs-list">
			{breadcrumbs.map((crumb, index) => (
				<Fragment key={crumb.label}>
					<li className={classNames("breadcrumb-item", {"allow-truncation": allowTruncation})}>
						<Crumb
							label={crumb.label}
							href={crumb.href}
							segmentLabel={crumb.segmentLabel}
							segmentProperties={crumb.segmentProperties}
							isLast={index === lastIndexNumber}
						/>
							{index !== lastIndexNumber && (
						<Text
							color="lightGray"
							weight="semiBold"
							className="breadcrumb-separator"
						>
							{" "}/{" "}
						</Text>
					)}
					</li>
				
				</Fragment>
			))}
		</ul>
	);
};

BreadcrumbsList.propTypes = {
	breadcrumbs: PropTypes.instanceOf(Array),
	allowTruncation: PropTypes.bool
};

export default BreadcrumbsList;
