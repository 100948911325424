import missingFacilityImage from "assets/images/missing-facility.jpg";

export const getFacilityCardProps = (facility) => {
	if (Object.keys(facility).length === 0) return null;

	const {
		name,
		images,
		location,
		address,
		facilityPhone,
		distance,
		url,
		lowestPrice,
		startingPrice,
		sortId,
		id,
		reviews,
		unitPromotion,
		recommendedUnit,
		closures,
		adminFee,
		lockFee,
		totalFees,
		showFeeWarning
	} = facility;

	const MAX_AMENITIES_TO_SHOW = 5;

	// images
	const IMAGE_BASE_PATH = "https://images.storage.com/medium-compress/";
	const facilityImage = images.length
		? `${IMAGE_BASE_PATH}${images[0]}`
		: missingFacilityImage;

	// format the object we'll turn into our SEO schema
	const schema = {
		name,
		location,
		reviews,
		facilityPhone,
		promotion: unitPromotion,
		// don't send the default to schema, it'll be base64
		images: images.length ? [facilityImage] : [],
		url,
		closures
	};

	return {
		name,
		sortId,
		id,
		showDefaultImage: !images.length,
		image: facilityImage,
		address,
		distance,
		url,
		startingPrice,
		lowestPrice,
		reviews,
		facilityPhone,
		amenities:
			recommendedUnit.availableAmenities?.slice(
				0,
				MAX_AMENITIES_TO_SHOW
			) || [],
		hasAdditionalAmenities:
			recommendedUnit.availableAmenities?.length > MAX_AMENITIES_TO_SHOW,
		schema,
		closures,
		adminFee,
		lockFee,
		totalFees,
		showFeeWarning,
	};
};

export const getFacilityWithUnits = (id, facilitiesById, unitsById) => {
	const facility = facilitiesById[id] || {};
	const facilityObject = { ...facility };
	if (Object.keys(facility).length > 0) {
		const recommendedUnitId = facilitiesById[id].units?.recommendedUnit;
		facilityObject.recommendedUnit = unitsById[recommendedUnitId] || {};
	}
	return facilityObject;
};

export default getFacilityWithUnits;
