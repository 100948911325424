import React from "react";
import PropTypes from "prop-types";

import { Link } from "components/core/Link";
import "./Crumb.scss";

const Crumb = ({ label, href, segmentLabel, segmentProperties, isLast }) => (
	<span className="crumb">
		{isLast ?
			label :
			(<Link
				href={href}
				weight="semiBold"
				segmentLabel={segmentLabel}
				segmentProperties={segmentProperties}
				underline
				color="blue"
				hover
			>
				{label}
			</Link>)
		}
	</span>
);

Crumb.defaultProps = {
	href: "",
	isLast: false,
	segmentLabel: "",
	segmentProperties: {}
};

Crumb.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string,
	isLast: PropTypes.bool,
	segmentLabel: PropTypes.string,
	segmentProperties: PropTypes.object
};

export default Crumb;
