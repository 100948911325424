import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import formatUSD from "utils/formatUSD";
import { Expand, Image } from "@sparefoot/react-commons";
import SelfStorageSchema from "@sparefoot/react-commons/SelfStorageSchema";

import { ClickToCall } from "components/brand/ClickToCall";
import { Button, TextButton } from "components/core/Button";
import { Link } from "components/core/Link";
import { Text, ListItem } from "components/core/Typography";
import { Chevron, MapPinFilled } from "components/core/Icons";
import { StarReviewsCount } from "components/review/StarReviewsCount";
import { getLocalNumber } from "store/modules/phone";
import "./FacilityCard.scss";
import FeeTooltip from "components/facility/FeeTooltip/FeeTooltip";

const AmenitiesDrawer = ({ showViewMoreLink, url, amenities, isOpen }) => (
	<Expand
		className="amenities-drawer"
		expanded={isOpen}
	>
		{/* This first inner div is consumed by the expand component */}
		<div>
			<div className="inner">
				<ul>
					{amenities.map((amenity) => (
						<ListItem
							size="small"
							last
							nowrap
							key={amenity}
						>
							{amenity}
						</ListItem>
					))}
				</ul>
				{showViewMoreLink && (
					<Link
						hover
						last
						weight="bold"
						size="small"
						color="lightGray"
						underline
						href={url}
						className="view-more-link"
					>
						View All Facility Features
					</Link>
				)}
			</div>
		</div>
	</Expand>
);

AmenitiesDrawer.propTypes = {
	amenities: PropTypes.arrayOf(PropTypes.string),
	showViewMoreLink: PropTypes.bool,
	isOpen: PropTypes.bool,
	url: PropTypes.string
};

const FacilityTitle = ({ name, address, url }) => (
	<div className="facility-title">
		<Link
			href={url}
			last
			display="block"
			weight="bold"
			className="facility-name"
			color="black"
		>
			{name}
		</Link>

		<Text
			last
			display="block"
			className="facility-address"
			mediumSize="small"
		>
			{address}
		</Text>
	</div>
);
FacilityTitle.propTypes = {
	name: PropTypes.string,
	address: PropTypes.string, 
	url: PropTypes.string
};

const FacilityDistance = ({ distance }) => (
	<div className="facility-distance">
		<MapPinFilled fill="blue" />
		<Text
			last
			size="small"
		>
			{`${distance} miles`}
		</Text>
	</div>
);
FacilityDistance.propTypes = {
	distance: PropTypes.number
};

const FacilityImage = ({ src, showDefaultImage, alt }) => (
	<div
		className={classnames("facility-image", {
			showDefaultImage: "show-default"
		})}
	>
		{showDefaultImage && (
			<div>
				<Text
					last
					weight="bold"
					color="white"
				>
					No facility image
				</Text>
			</div>
		)}
		<Image
			lazy
			src={src}
			alt={alt}
		/>
	</div>
);

FacilityImage.propTypes = {
	showDefaultImage: PropTypes.bool,
	src: PropTypes.string,
	alt: PropTypes.string
};

const FacilityPrice = ({ startingPrice, lowestPrice, totalFees, fees, showFeeWarning }) => {
	const showStartingPrice = startingPrice && lowestPrice < startingPrice;
	return (
		<div className="facility-price">
			<Text
				last
				size="small"
			>
				Starting at
			</Text>
			<div className="prices">
				<Text
					last
					color="green"
					weight="bold"
					className="lowest-price"
				>
					{formatUSD(lowestPrice, { showDecimal: true })}
				</Text>
				{showStartingPrice && (
					<Text
						last
						size="small"
						strikethrough
						className="starting-price"
					>
						{formatUSD(startingPrice)}
					</Text>
				)}
				<Text
					last
					size="tiny"
					color="lightGray"
					className="price-label"
				>
					per month
				</Text>
			</div>
			{showFeeWarning && totalFees > 0 && 
				<FeeTooltip
					fees={fees}
					totalFees={totalFees}
				/>
			}
		</div>
	);
};
FacilityPrice.propTypes = {
	startingPrice: PropTypes.number,
	lowestPrice: PropTypes.number,
	fees: PropTypes.arrayOf(PropTypes.object),
	showFeeWarning: PropTypes.bool,
	totalFees: PropTypes.number
};

const AmenitiesButton = ({ isOpen, handleClick }) => (
	<TextButton
		type="button"
		onClick={handleClick}
		className="view-amenities-button"
		flush
		reverse
		icon={
			<Chevron
				fill="lightGray"
				rotate={isOpen ? "up" : "down"}
			/>
		}
	>
		View Features
	</TextButton>
);

AmenitiesButton.propTypes = {
	isOpen: PropTypes.bool,
	handleClick: PropTypes.func
};

const ReserveNowButton = ({ url }) => (
	<Button
		href
		onClick={() => window.location.assign(url)}
		theme="primary"
		className="reserve-button"
		segmentLabel="reserve-button"
	>
		View All Units
	</Button>
);
ReserveNowButton.propTypes = {
	url: PropTypes.string
};

// --------------------------------------------------
const FacilityCard = ({
	name,
	layout,
	image,
	showDefaultImage,
	address,
	distance,
	url,
	lowestPrice,
	startingPrice,
	reviews,
	amenities,
	hasAdditionalAmenities,
	schema,
	totalFees,
	lockFee,
	adminFee,
	showFeeWarning
}) => {
	const [phone, setPhone] = useState({});
	const dispatch = useDispatch();
	const handleFetchNumber = async ({ city, state }) => {
		const data = { path: `${city}/${state}` };
		const {
			payload: { result }
		} = await dispatch(getLocalNumber(data));
		setPhone(result);
		return result;
	};
	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleCardInteraction = (e) => {
		if (!e.target.closest("a, button")) {
			window.location.assign(url);
		}
	};

	const handleKeyUp = (e) => {
		if (e.key === "Enter") {
			handleCardInteraction(e);
		}
	};

	const toggleDrawer = () => setDrawerOpen(!drawerOpen);
	const showAmenities = amenities.length > 0;

	const fees = [{
		name: 'One-Time Admin Fee',
		price: adminFee
	},
	{
		name: 'One-Time Lock Fee',
		price: lockFee
	}]

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			role="link"
			tabIndex={0}
			onClick={handleCardInteraction}
			onKeyUp={handleKeyUp}
			className={classnames("facility-card", { drawerOpen })}
		>
	
			<SelfStorageSchema {...schema} />
			<div className="card-main">
				<div
					className={classnames("card-top", `card-layout-${layout}`)}
				>
					<FacilityImage
						src={image}
						showDefaultImage={showDefaultImage}
						alt={name}
					/>
					<FacilityTitle
						url={url}
						name={name}
						address={address}
					/>
					<StarReviewsCount
						className="facility-reviews"
						url={`${url}#reviews`}
						number={reviews.number}
						average={reviews.average}
					/>
					<FacilityDistance distance={distance} />
					<ClickToCall
						underline
						handleFetchNumber={() => 
							handleFetchNumber(schema?.location) 
						}
						phone={phone}
					/>
				</div>
				<div className="card-bottom">
					<FacilityPrice
						startingPrice={startingPrice}
						lowestPrice={lowestPrice}
						fees={fees}
						totalFees={totalFees}
						showFeeWarning={showFeeWarning}
					/>
					<ReserveNowButton url={url} />
					{showAmenities && (
						<AmenitiesButton
							isOpen={drawerOpen}
							handleClick={toggleDrawer}
						/>
					)}
				</div>
			</div>
			{showAmenities && (
				<AmenitiesDrawer
					showViewMoreLink={hasAdditionalAmenities}
					url={url}
					isOpen={drawerOpen}
					amenities={amenities}
				/>
			)}
		</div>
	);
};

FacilityCard.propTypes = {
	name: PropTypes.string,
	layout: PropTypes.oneOf(["wide", "narrow"]),
	image: PropTypes.string,
	address: PropTypes.string,
	distance: PropTypes.number,
	url: PropTypes.string,
	startingPrice: PropTypes.number,
	lowestPrice: PropTypes.number,
	showDefaultImage: PropTypes.bool,
	reviews: PropTypes.shape({
		average: PropTypes.number,
		number: PropTypes.number
	}),
	amenities: PropTypes.arrayOf(PropTypes.string),
	hasAdditionalAmenities: PropTypes.bool,
	schema: PropTypes.object,
	totalFees: PropTypes.number,
	lockFee: PropTypes.number,
	adminFee: PropTypes.number,
	showFeeWarning: PropTypes.bool
};

FacilityCard.defaultProps = {
	layout: "wide"
};
export default FacilityCard;
