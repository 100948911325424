import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SortSelect from "components/search/SortSelect";
import searchPageContext from "pages/SearchPage/SearchPageContext";
import { PageTypes } from "@sparefoot/segment-react";

import { Text } from "components/core/Typography";
import { Filter, Cross } from "components/core/Icons";
import { TextButton } from "components/core/Button";

import "./SearchToolbar.scss";

const SearchToolbar = ({ count, showSortSelect = true, currentRange }) => {
	const {
		location,
		setShowFilterModal,
		showFilterModal,
		handleSortChange,
		selectedSort,
		pageType,
		searchFilters,
		handleFilterChange
	} = useContext(searchPageContext);
	let selectedSearchFilters = [];
	if (searchFilters && searchFilters.length > 0) {
		selectedSearchFilters = searchFilters
			.map((item) =>
				Object.values(item.options).filter((option) => option.checked)
			)
			.flat(Infinity);
	}
	const { city, state } = location;

	const preposition = pageType === PageTypes.SEARCH ? "in" : "near";
	const resultsText = `${
		currentRange || `1-${count < 20 ? count : "20"}`
	} of ${count} storage facilities ${preposition} ${city}, ${state}`;

	const handleFiltering = (ev) => {
		handleFilterChange(ev, true);
	};
	const handleClearAll = () => {
		selectedSearchFilters.map((item, i) =>
			handleFilterChange(
				{
					target: {
						name: item.name,
						value: item.value,
						checked: false
					}
				},
				selectedSearchFilters.length - 1 === i
			)
		);
	};
	return (
		<div
			className={classnames("search-toolbar", {
				divider: !showSortSelect
			})}
		>
			{/* X facilities found near Y, Z */}
			<Text
				last
				weight="bold"
				largeSize="small"
				className="results-count"
			>
				{resultsText}
			</Text>
			{showSortSelect && (
				<SortSelect
					onChange={handleSortChange}
					selected={selectedSort}
				/>
			)}
			{/* Mobile-only Filter button */}
			<div className="mobile-filters">
				<div className="filter-buttons">
					<TextButton
						label="Show Filter Modal"
						className="results-filter"
						fullWidth={false}
						onClick={() => setShowFilterModal(!showFilterModal)}
						flush
						icon={<Filter />}
					>
						Filter Results
					</TextButton>
					{selectedSearchFilters.length > 0 && (
						<>
							{selectedSearchFilters.map((item) => (
								<TextButton
									key={item.value}
									label="Applied Filter"
									className="selected-filter"
									fullWidth={false}
									flush
									icon={
										<Cross
											onClick={() =>
												handleFiltering({
													target: {
														name: item.name,
														value: item.value,
														checked: false
													}
												})
											}
										/>
									}
									reverse
									color="red"
								>
									{item.label}
								</TextButton>
							))}
							<TextButton
								key="clearAll"
								id="clearAll"
								fullWidth={false}
								color="blue"
								onClick={() => handleClearAll()}
							>
								Clear All
							</TextButton>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
SearchToolbar.propTypes = {
	count: PropTypes.number.isRequired,
	showSortSelect: PropTypes.bool,
	currentRange: PropTypes.string
};
export default SearchToolbar;
